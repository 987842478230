export enum DeploymentType {
  LIVE = "live",
  LIVE_INTEGRATION = "live-integration",
  LIVE_NEW = "live-new",
  LIVE_NEW_INTEGRATION = "live-new-integration",
  DZ = "dz",
  DZ_INTEGRATION = "dz-integration",
  DZ_LEGACY = "dz-legacy",
  LOCAL = "local"
}

export const deploymentTypes: DeploymentType[] = Object.values(DeploymentType);
