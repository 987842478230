import {ClientEntity} from "@common-core/coat-operational-hierarchy-appsync-model";
import {NamedSources} from "../operational-hierarchy/overview-tab";

export const toNamedSources = (clients: ClientEntity[]) => {
  return clients.map(client => {
    return {
      sourceId: client.caId,
      name: client.custName,
      sourceType: "CACM"
    } as NamedSources;
  });
};
