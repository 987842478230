/* eslint-disable no-console */
import {TabBody, TabColumn} from "../../tab-content";
import {HashTab} from "../../hash-tabs";
import {
  EmbeddedComponentProps,
  EmbeddedComponentLoader
} from "./EmbeddedComponentLoader";
import {useEffect} from "react";

export const EmbeddedComponentTab: HashTab<EmbeddedComponentProps> = ({
  definition,
  entity
}) => {
  useEffect(() => {
    console.info(`Rendering EmbeddedComponentTab:${definition.element}`);
    return () => {
      console.info(`Cleanup EmbeddedComponentTab:${definition.element}`);
    };
  }, [definition.element]);

  return (
    <TabBody name={definition.element}>
      <TabColumn name={"main"}>
        <EmbeddedComponentLoader definition={definition} entity={entity} />
      </TabColumn>
    </TabBody>
  );
};
