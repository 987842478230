import {QueryResult} from "@apollo/client";
import {BackendError} from "@common-core/runtime-js/backend";
import {
  ClientEntity,
  OperationalEntity
} from "@common-core/coat-operational-hierarchy-appsync-model";
import {EntityResponse} from "../../backend";

export const assertEntityPresent = (
  result: QueryResult<EntityResponse<OperationalEntity>>
): OperationalEntity => {
  if (!result.data?.entity) {
    // TODO: change this backend error to something like Operational Entity not found
    throw new BackendError(`Client Entities of "sources" not found`, 404);
  }
  return result.data.entity;
};

export const assertClientEntityPresent = (
  result: QueryResult<EntityResponse<ClientEntity>>
): ClientEntity => {
  if (!result.data?.entity) {
    throw new BackendError(`Client entity not found`, 404);
  }
  return result.data.entity;
};
