import {FC, useState} from "react";
import {useTranslation} from "react-i18next";
import {
  SimpleTable,
  TableHead,
  TableBody,
  TableRow,
  TableCell
} from "@interstate/components/SimpleTable";
import {TabRow} from "../../tab-content";
import "./Sources.scss";
import {Source} from "@common-core/coat-operational-hierarchy-appsync-model";
import {OnCompleteCallback} from "../../utils";
import {
  EditMultipleSourcesModal,
  NamedSources,
  EditSourcesModal
} from "../operational-hierarchy/overview-tab";
import {
  ActionButtons,
  CopyButton,
  PermissionBasedEditButton
} from "../../action-buttons";
import {Typography} from "@interstate/components/Typography";
import {useFlags} from "launchdarkly-react-client-sdk";
import {useLazyQuery} from "@apollo/client";
import {
  LookUpClientEntitiesByIds,
  LookupClientEntitiesResponse
} from "../backend";
import {Endpoints} from "../../runtime";
import {assertClientsPresent} from "../boids/transforms";
import {useToastErrorHandler} from "../../backend";
import {toNamedSources} from "../util/toNamedSources";
import {AuthorizationCheck, Authorized, Unauthorized} from "../../access";

export interface SourceProps {
  id: string;
  sources: Source[] | undefined;
}

export const Sources: FC<SourceProps> = ({id, sources}) => {
  const {t} = useTranslation();
  const flags = useFlags();
  const [namedSources, setNamedSources] = useState<NamedSources[]>([]);
  const [editingSources, setEditingSources] = useState<boolean>(false);
  const toastError = useToastErrorHandler();
  const finishSourcesEditing: OnCompleteCallback = () => {
    setNamedSources([]);
    setEditingSources(false);
  };
  const [lookUpClientEntities, {loading}] =
    useLazyQuery<LookupClientEntitiesResponse>(LookUpClientEntitiesByIds, {
      context: {
        endpoint: Endpoints.APPSYNC
      },
      fetchPolicy: "network-only"
    });
  const beginEditing = () => {
    if (sources) {
      const ids = sources.map(source => source.sourceId);
      lookUpClientEntities({variables: {ids}})
        .then(assertClientsPresent)
        .then(toNamedSources)
        .then(namedSources => {
          setNamedSources(namedSources);
        })
        .then(() => setEditingSources(true))
        .catch(toastError);
    }
  };
  return (
    <AuthorizationCheck
      permissions={[
        {resource: "operational-entity", action: "create-complex-entities"}
      ]}>
      <Authorized>
        <TabRow
          name={"sources"}
          header={
            <h4>
              {t("entity-detail.dealer.sources.details")}
              <PermissionBasedEditButton
                onClick={beginEditing}
                loading={loading}
                qualifier={"source"}
                tooltip={t("entity-detail.dealer.sources.edit-all")}
                inline={false}
                permissions={[
                  {
                    resource: "operational-entity",
                    action: "edit"
                  },
                  {
                    resource: "operational-entity",
                    action: "create-complex-entities"
                  }
                ]}
              />
            </h4>
          }>
          {editingSources && (
            <EditMultipleSourcesModal
              id={id}
              initalSources={
                !sources || namedSources.length === 0 ? [] : namedSources
              }
              onComplete={finishSourcesEditing}
            />
          )}
          {sources && sources.length > 0 ? (
            <SimpleTable
              id={`source-table-${id}`}
              data-testid={`source-table-${id}`}
              dataDensity={"small"}
              className={"source-table"}
              background={"white"}
              hover={false}>
              <TableHead>
                <TableRow>
                  <TableCell className={"source-type"}>
                    {t("entity-detail.dealer.sources.type")}
                  </TableCell>
                  <TableCell className={"source-id"}>
                    {t("entity-detail.dealer.sources.id")}
                  </TableCell>
                  <TableCell className={"source-actions"} />
                </TableRow>
              </TableHead>
              <TableBody>
                {sources.map((source: Source) => (
                  <TableRow key={`${source.sourceType}|${source.sourceId}`}>
                    <TableCell className={"source-type"}>
                      {source.sourceType}
                    </TableCell>
                    <TableCell className={"source-id"}>
                      {source.sourceId}
                    </TableCell>
                    <TableCell className={"source-actions"}>
                      <ActionButtons>
                        <CopyButton
                          qualifier={source.sourceId}
                          item={t("entity-detail.dealer.sources.id")}
                          content={source.sourceId}
                          inline={true}
                        />
                      </ActionButtons>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </SimpleTable>
          ) : (
            <Typography
              tag={"span"}
              className={"no-sources"}
              variant={"body-sm"}>
              {t("entity-detail.dealer.sources.none")}
            </Typography>
          )}
        </TabRow>
      </Authorized>
      <Unauthorized>
        <TabRow
          name={"sources"}
          header={
            <h4>
              {t("entity-detail.dealer.sources.details")}
              {(!sources || sources.length === 0) && (
                <PermissionBasedEditButton
                  onClick={() => {
                    setEditingSources(true);
                  }}
                  qualifier={"source"}
                  tooltip={t("entity-detail.dealer.sources.edit-all")}
                  inline={false}
                  permissions={[
                    {
                      resource: "operational-entity",
                      action: "edit"
                    }
                  ]}
                />
              )}
            </h4>
          }>
          {editingSources && (
            <EditSourcesModal
              id={id}
              initalSources={!sources || sources.length === 0 ? [] : sources}
              onComplete={finishSourcesEditing}
            />
          )}
          {sources && sources.length > 0 ? (
            <SimpleTable
              id={`source-table-${id}`}
              data-testid={`source-table-${id}`}
              dataDensity={"small"}
              className={"source-table"}
              background={"white"}
              hover={false}>
              <TableHead>
                <TableRow>
                  <TableCell className={"source-type"}>
                    {t("entity-detail.dealer.sources.type")}
                  </TableCell>
                  <TableCell className={"source-id"}>
                    {t("entity-detail.dealer.sources.id")}
                  </TableCell>
                  <TableCell className={"source-actions"} />
                </TableRow>
              </TableHead>
              <TableBody>
                {sources.map((source: Source) => (
                  <TableRow key={`${source.sourceType}|${source.sourceId}`}>
                    <TableCell className={"source-type"}>
                      {source.sourceType}
                    </TableCell>
                    <TableCell className={"source-id"}>
                      {source.sourceId}
                    </TableCell>
                    <TableCell className={"source-actions"}>
                      <ActionButtons>
                        <CopyButton
                          qualifier={source.sourceId}
                          item={t("entity-detail.dealer.sources.id")}
                          content={source.sourceId}
                          inline={true}
                        />
                        <PermissionBasedEditButton
                          onClick={() => {
                            setEditingSources(true);
                          }}
                          qualifier={source.sourceId}
                          tooltip={t("entity-detail.dealer.sources.edit-one")}
                          inline={true}
                          permissions={[
                            {
                              resource: "operational-entity",
                              action: "edit"
                            }
                          ]}
                        />
                      </ActionButtons>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </SimpleTable>
          ) : (
            <Typography
              tag={"span"}
              className={"no-sources"}
              variant={"body-sm"}>
              {t("entity-detail.dealer.sources.none")}
            </Typography>
          )}
        </TabRow>
      </Unauthorized>
    </AuthorizationCheck>
  );
};
