import {TabRow} from "../../../tab-content";
import {CheckCircleIcon} from "@interstate/components/Icons";
import "./ComposedSolutions.scss";
import {useOperationalEntity} from "../../context";
import {EditComposedSolutionsModal} from "./EditComposedSolutionsModal";
import {PermissionBasedEditButton} from "../../../action-buttons";
import {useState} from "react";
import {OnCompleteCallback} from "../../../utils";
import {useTranslation} from "react-i18next";
import {Typography} from "@interstate/components/Typography";
export const ComposedSolutions = () => {
  const {t} = useTranslation();

  const {
    entity: {id, composedSolutions}
  } = useOperationalEntity();

  // Sort the composed solutions by name in alphabetical order
  const sortedComposedSolutions = composedSolutions
    ? composedSolutions.toSorted((a, b) =>
        a.composedSolutionName.localeCompare(b.composedSolutionName)
      )
    : [];
  const enabledComposedSolutions = sortedComposedSolutions.filter(
    composedSolutionAssignment => composedSolutionAssignment.enabled
  );
  const [editingComposedSolutions, setEditingComposedSolutions] =
    useState<boolean>(false);
  const finishComposedSolutionEditing: OnCompleteCallback = () => {
    setEditingComposedSolutions(false);
  };

  return (
    <TabRow
      name={"composed-solutions"}
      header={
        <header
          id={`composed-solutions-header-${id}`}
          data-testid={`composed-solutions-header-${id}`}
          className={"details-column-header"}>
          {editingComposedSolutions && (
            <EditComposedSolutionsModal
              id={id}
              onComplete={finishComposedSolutionEditing}
              initialComposedSolutions={sortedComposedSolutions}
            />
          )}
          <hgroup className={"details-column-header-group"}>
            <h4>{t("entity-detail.dealer.composed-solutions.details")}</h4>
            <PermissionBasedEditButton
              onClick={() => setEditingComposedSolutions(true)}
              qualifier={"composed-solutions"}
              tooltip={t("entity-detail.dealer.composed-solutions.edit")}
              permissions={[
                {
                  resource: "operational-entity",
                  action: "edit-composed-solutions"
                }
              ]}
            />
          </hgroup>
        </header>
      }>
      {enabledComposedSolutions.length > 0 ? (
        enabledComposedSolutions.map(composedSolutionAssignment => (
          <div
            key={composedSolutionAssignment.composedSolutionId}
            id={`composed-solution-${composedSolutionAssignment.composedSolutionId}`}
            data-testid={`composed-solution-${composedSolutionAssignment.composedSolutionId}`}
            className={"composed-solution-item"}>
            <CheckCircleIcon className={"composed-solution-item-icon"} />
            {composedSolutionAssignment.composedSolutionName}
          </div>
        ))
      ) : (
        <Typography
          tag={"span"}
          className={"no-composed-solutions"}
          variant={"body-sm"}>
          {t("entity-detail.dealer.composed-solutions.none")}
        </Typography>
      )}
    </TabRow>
  );
};
