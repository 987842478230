import {ModalWithAlert, useAlert} from "../../../alerting";
import {useTranslation} from "react-i18next";
import {FC, useState} from "react";
import {OnCompleteCallback} from "../../../utils";
import {CheckBox} from "@interstate/components/CheckBox";
import {useLazyQuery, useMutation} from "@apollo/client";
import {
  EntityResponse,
  LookupOperationalEntity,
  SetComposedSolutionEnabled
} from "../../backend";
import {
  ComposedSolutionAssignment,
  OperationalEntity,
  UpdateResponse
} from "@common-core/coat-operational-hierarchy-appsync-model";
import {Endpoints} from "../../../runtime";
import {assertEntityPresent} from "../../boids/transforms";
import {useOperationalEntity} from "../../context";

export const EditComposedSolutionsModal: FC<{
  id: string;
  initialComposedSolutions: ComposedSolutionAssignment[];
  onComplete: OnCompleteCallback;
}> = ({id, initialComposedSolutions, onComplete}) => {
  const {t} = useTranslation();
  const {alert, clearAlert, createErrorAlert} = useAlert({
    errorTitleKey: "toast.backend-error"
  });
  const [working, setWorking] = useState(false);
  const [composedSolutions, setComposedSolutions] = useState(
    initialComposedSolutions
  );
  const {entity, setEntity} = useOperationalEntity();

  const [lookUpOperationalEntity] = useLazyQuery<
    EntityResponse<OperationalEntity>
  >(LookupOperationalEntity, {
    context: {
      endpoint: Endpoints.APPSYNC
    },
    fetchPolicy: "network-only"
  });

  const [setComposedSolutionEnabled] = useMutation<UpdateResponse>(
    SetComposedSolutionEnabled,
    {
      context: {
        endpoint: Endpoints.APPSYNC
      }
    }
  );

  const refreshEntity = async (): Promise<void> => {
    return lookUpOperationalEntity({variables: {id: entity.id}})
      .then(assertEntityPresent)
      .then(setEntity);
  };

  const composedSolutionChanged = async (event: any): Promise<void> => {
    const changedComposedSolutionIndex = composedSolutions.findIndex(
      composedSolution =>
        composedSolution.composedSolutionId === event.target.value.checkboxValue
    );
    setComposedSolutions([
      ...composedSolutions.slice(0, changedComposedSolutionIndex),
      {
        ...composedSolutions[changedComposedSolutionIndex],
        enabled: event.target.value.checked
      },
      ...composedSolutions.slice(changedComposedSolutionIndex + 1)
    ]);
  };

  const setComposedSolutionEnabledValues = async (
    composedSolutions: ComposedSolutionAssignment[]
  ) => {
    for (const [index, composedSolution] of composedSolutions.entries()) {
      // Only update the enabled status if it has changed
      if (
        composedSolution.enabled !== initialComposedSolutions[index].enabled
      ) {
        const composedSolutionAssignment = {
          composedSolutionId: composedSolution.composedSolutionId,
          commonOrgId: id,
          enabled: composedSolution.enabled
        };
        await setComposedSolutionEnabled({
          variables: {
            composedSolutionAssignment
          }
        });
      }
    }
  };

  const saveChanges = async () => {
    setWorking(true);
    clearAlert();
    setComposedSolutionEnabledValues(composedSolutions)
      .then(refreshEntity)
      .then(() => onComplete(true))
      .catch(createErrorAlert)
      .finally(() => setWorking(false));
  };

  const cancelChanges = () => {
    onComplete(true);
  };

  return (
    <ModalWithAlert
      id={"edit-composed-solutions-modal"}
      data-testid={"edit-composed-solutions-modal"}
      show={true}
      size={"large"}
      header={t("modal.header.edit-composed-solutions")}
      alert={alert}
      footer={{
        primary: {
          action: saveChanges,
          label: t("common-actions.save-changes"),
          isLoading: working
        },
        options: {
          action: cancelChanges,
          label: t("common-actions.cancel"),
          isLoading: working,
          buttonStyle: "tertiary"
        }
      }}
      onHide={cancelChanges}>
      {composedSolutions.map(composedSolution => (
        <CheckBox
          key={composedSolution.composedSolutionId}
          id={`checkbox-${composedSolution.composedSolutionId}`}
          data-testid={`checkbox-${composedSolution.composedSolutionId}`}
          value={composedSolution.composedSolutionId}
          onChange={composedSolutionChanged}
          label={composedSolution.composedSolutionName}
          checked={composedSolution.enabled}
        />
      ))}
    </ModalWithAlert>
  );
};
