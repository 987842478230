import {Runtime, RuntimeTransform} from "@common-core/runtime-js/runtime";
import {isDz, isLive, Metadata} from "./Metadata";

export const grouper: RuntimeTransform<Metadata, string> = (
  runtime: Runtime<Metadata>
): string => {
  const metadata = runtime.deployment.metadata;
  if (isLive(metadata)) {
    return "Live Deployments";
  }
  if (isDz(metadata)) {
    return "Dangerzone Deployments";
  }
  return "Local Deployments";
};
