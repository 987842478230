import {FC, useRef, useState} from "react";
import {TextInput} from "@interstate/components/TextInput";
import {useOperationalEntity} from "../../context";
import {
  assertClientEntityPresent,
  assertEntityPresent
} from "../../boids/transforms";
import {useLazyQuery, useMutation} from "@apollo/client";
import {
  EntityResponse,
  LookupOperationalEntity,
  UpdateOperationalEntitySources
} from "../../backend";
import {
  ClientEntity,
  OperationalEntity,
  Source,
  SourceType,
  UpdateResponse,
  UpdateSourcesInput
} from "@common-core/coat-operational-hierarchy-appsync-model";
import {Endpoints} from "../../../runtime";
import {useTranslation} from "react-i18next";
import {ErrorHandler, OnCompleteCallback} from "../../../utils";
import {ModalWithAlert, useAlert} from "../../../alerting";
import "./EditMultipleSourcesModal.scss";
import {
  SimpleTable,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@interstate/components/SimpleTable";
import {ActionButtons} from "../../../action-buttons";
import {Button} from "@interstate/components/Button";
import {PlusCircleIcon, TrashIcon} from "@interstate/components/Icons";
import {ShowMore} from "@interstate/components/ShowMore";
import {LookupClientEntityById} from "../../client-hierarchy/backend";
import {Tooltip} from "@interstate/components/Tooltip";
import {Typography} from "@interstate/components/Typography";

export interface NamedSources {
  sourceId: string;
  sourceType: SourceType;
  name: string;
}

export const EditMultipleSourcesModal: FC<{
  id: string;
  initalSources: NamedSources[];
  onComplete: OnCompleteCallback;
}> = ({id, initalSources, onComplete}) => {
  const {t} = useTranslation();
  const {alert, setAlert, createErrorAlert} = useAlert({
    errorTitleKey: "toast.backend-error"
  });
  const [inputErrorMessage, setInputErrorMessage] = useState<
    string | undefined
  >();
  const [working, setWorking] = useState(false);
  const [workingAddEntity, setWorkingAddEntity] = useState(false);
  const [sources, setSources] = useState(initalSources);
  const [value, setValue] = useState<string>("");
  const [disabledButton, setDisabledButton] = useState(true);
  const [expanded, setExpanded] = useState(false);
  const {entity, setEntity} = useOperationalEntity();
  const inputRef = useRef<any>(null);
  const [lookUpOperationalEntity] = useLazyQuery<
    EntityResponse<OperationalEntity>
  >(LookupOperationalEntity, {
    context: {
      endpoint: Endpoints.APPSYNC
    },
    fetchPolicy: "network-only"
  });
  const [lookupClientEntity] = useLazyQuery<EntityResponse<ClientEntity>>(
    LookupClientEntityById,
    {
      variables: {id},
      fetchPolicy: "no-cache",
      context: {
        endpoint: Endpoints.APPSYNC
      }
    }
  );

  const [updateSources] = useMutation<UpdateResponse>(
    UpdateOperationalEntitySources,
    {
      context: {
        endpoint: Endpoints.APPSYNC
      }
    }
  );

  const clearErrors = (): void => {
    setAlert(undefined);
    setInputErrorMessage(undefined);
  };

  const handleError: ErrorHandler = (error: any) => {
    const clientValidationError = error.graphQLErrors.find(
      (graphQLerror: any) => graphQLerror.errorType == "ClientValidationError"
    );
    if (clientValidationError) {
      const invalidIds = clientValidationError.errorInfo.invalidIds;
      let errorMessage = "Please correct your entry. CACM ID";

      switch (invalidIds.length) {
        case 0:
          errorMessage += " not found";
          break;
        case 1:
          errorMessage += `: ${invalidIds[0]} was not found`;
          break;
        default: {
          const lastId = invalidIds.pop();
          errorMessage += `'s: ${invalidIds.join(", ")}, and ${lastId} were not found`;
          break;
        }
      }
      setInputErrorMessage(errorMessage);
    } else {
      createErrorAlert(error);
    }
  };

  const handleNullClient = () => {
    const errorMessage = `Please correct your entry. CACM ID: ${value} was not found`;
    setInputErrorMessage(errorMessage);
  };

  const refreshEntity = async (): Promise<void> => {
    return await lookUpOperationalEntity({variables: {id: entity.id}})
      .then(assertEntityPresent)
      .then(setEntity);
  };

  const saveChanges = async () => {
    setWorking(true);
    clearErrors();
    const unnamedSources = sources.map(source => {
      return {
        sourceId: source.sourceId,
        sourceType: source.sourceType
      } as Source;
    });
    const sourcesInput: UpdateSourcesInput = {
      entityId: id,
      sources: unnamedSources
    };
    await updateSources({
      variables: {sourcesInput}
    })
      .then(refreshEntity)
      // If the update call returns an error, give the user the opportunity to try again
      .then(() => onComplete(true))
      .catch(handleError)
      .finally(() => setWorking(false));
  };

  const cancelChanges = () => {
    onComplete(true);
  };

  const onInputChange = (event: any) => {
    const val = event.target?.value.trim();
    setValue(val);
    if (val === "") {
      setDisabledButton(true);
    } else if (disabledButton) {
      setDisabledButton(false);
    }
  };
  const addSource = async () => {
    setWorkingAddEntity(true);
    await lookupClientEntity({variables: {id: value}})
      .then(assertClientEntityPresent)
      .then(result => {
        setSources([
          ...sources,
          {sourceId: result.caId, sourceType: "CACM", name: result.custName}
        ]);
        setValue("");
        clearErrors();
        setExpanded(false);
      })
      .catch(handleNullClient)
      .finally(() => setWorkingAddEntity(false));
  };
  const removeSource = (id: string) => {
    setSources(sources.filter(source => source.sourceId !== id));
  };
  const addAdditionalEntity = () => {
    setExpanded(true);
  };
  const cancelAddSource = () => {
    setValue("");
    clearErrors();
    setExpanded(false);
  };
  return (
    <ModalWithAlert
      id={"edit-source-modal"}
      data-testid={"edit-source-modal"}
      show={true}
      size={"large"}
      header={t("modal.header.edit-source")}
      alert={alert}
      footer={{
        primary: {
          action: saveChanges,
          label: t("common-actions.save-changes"),
          isLoading: working,
          disabled: working || workingAddEntity
        },
        options: {
          action: cancelChanges,
          label: t("common-actions.cancel"),
          isLoading: working,
          buttonStyle: "tertiary"
        }
      }}
      onHide={cancelChanges}>
      {sources && sources.length > 0 && (
        <SimpleTable
          background={"white"}
          id={`edit-source-table-${id}`}
          data-testid={`edit-source-table-${id}`}
          dataDensity={"small"}
          className={"edit-source-table"}
          hover={false}>
          <TableHead>
            <TableRow>
              <TableCell className={"source-id"}>
                {t("entity-detail.dealer.sources.id")}
              </TableCell>
              <TableCell className={"source-source"}>
                {t("entity-detail.dealer.sources.source")}
              </TableCell>
              <TableCell className={"source-name"}>
                {t("entity-detail.dealer.sources.name")}
              </TableCell>
              <TableCell className={"source-actions"}>
                {t("entity-detail.dealer.sources.actions")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sources.map((source: NamedSources) => (
              <TableRow key={`${source.sourceType}|${source.sourceId}`}>
                <TableCell className={"source-id"}>{source.sourceId}</TableCell>
                <TableCell className={"source-source"}>
                  {source.sourceType}
                </TableCell>
                <TableCell className={"source-name"}>{source.name}</TableCell>
                <TableCell className={"source-actions"}>
                  <ActionButtons>
                    <Tooltip
                      position={"top"}
                      toolTipContent={t("common-actions.trash")}
                      size={"small"}>
                      <Button
                        id={`remove-${source.sourceId}-button`}
                        data-testid={`remove-${source.sourceId}-button`}
                        icon={<TrashIcon />}
                        onClick={() => removeSource(source.sourceId)}
                        buttonStyle={"tertiary"}
                      />
                    </Tooltip>
                  </ActionButtons>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </SimpleTable>
      )}
      {!sources ||
        (sources.length === 0 && (
          <Typography variant={"body-lg"} className={"no-sources"}>
            {t("entity-detail.dealer.sources.none")}
          </Typography>
        ))}
      <ShowMore expand={expanded}>
        <div className={"add-entity"}>
          <TextInput
            id={"source-text-input"}
            data-testid={"source-text-input"}
            className={"source-text-input"}
            name={"source"}
            value={value}
            label={`${t("modal.text-input.edit-source")}`}
            onChange={onInputChange}
            onKeyDown={async (event: any) => {
              if (event.key === "Enter" && !disabledButton) {
                await addSource();
              }
            }}
            clearButton={!!sources[0]}
            hasError={!!inputErrorMessage}
            errorMessage={inputErrorMessage}
            ref={inputRef}
          />
          <Button
            id={"add-source-button"}
            data-testid={"add-source-button"}
            className={"add-source-button"}
            buttonStyle={"primary"}
            onClick={async () => await addSource()}
            disabled={disabledButton || working}
            isLoading={workingAddEntity}>
            {t("common-actions.add-entity")}
          </Button>
          <Button
            id={"cancel-source-button"}
            data-testid={"cancel-source-button"}
            className={"cancel-source-button"}
            buttonStyle={"secondary"}
            onClick={cancelAddSource}
            disabled={workingAddEntity || working}>
            {t("common-actions.cancel")}
          </Button>
        </div>
      </ShowMore>
      <div className={"add-additional-entity"}>
        <Button
          className={"add-additional-entity-button"}
          buttonStyle={"tertiary"}
          startIcon={<PlusCircleIcon />}
          onClick={addAdditionalEntity}
          disabled={expanded}>
          {t("common-actions.add-additional-entity")}
        </Button>
      </div>
    </ModalWithAlert>
  );
};
