import {FC, PropsWithChildren, useMemo, useRef, useEffect} from "react";
import {useEnvironment} from "@common-core/react-runtime/context";
import {useOktaAuth} from "@okta/okta-react";
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  from,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject
} from "@apollo/client";
import {removeTypenameFromVariables} from "@apollo/client/link/remove-typename";
import {useFlags} from "launchdarkly-react-client-sdk";
import {features} from "../features";
import {Endpoints, Environment} from "../runtime";
import {v4 as uuidv4} from "uuid";

/**
 * {@link GraphqlClientProvider} provides the Apollo Client to all downstream components
 * allowing them to call backend endpoints. Endpoints are provided the environment and
 * limited to those declared in {@link Endpoints}. The client is initialized with a terminating
 * HttpLink which checks what endpoint was sent in the operation's context and chooses the correct
 * url based on that endpoint.
 * See https://www.apollographql.com/docs/react/api/link/introduction for link documentation
 * and https://www.apollographql.com/docs/react/api/core/ApolloClient for client documentation.
 *
 * New data sources (read: endpoints) will need to be added to {@link Endpoints}, the switch statement below,
 * {@link environments}, and {@link Backends}.
 * @param children
 * @constructor
 */
export const GraphqlClientProvider: FC<PropsWithChildren> = ({children}) => {
  const environment = useEnvironment<Environment>();
  const {authState} = useOktaAuth();
  const flags = useFlags();
  const connectToDevTools = flags[features.ops.apolloClientConnectToDevTools];

  const setCorrelationIdHeader = new ApolloLink((operation, forward) => {
    operation.setContext(() => ({
      headers: {"X-CoxAuto-Correlation-Id": uuidv4()}
    }));
    return forward(operation);
  });

  // Use useRef to store the latest tokens so that we can update it for the authLink while allowing updates to get through to the
  // memoized version of the client
  const latestAuthState = useRef(authState);
  useEffect(() => {
    latestAuthState.current = authState;
  }, [authState]);

  // Set this up outside the memo hook so that we don't re-init the client when the access or id token changes, using the
  // link method will allow us to update the tokens on each request without creating the client and invalidating caches.
  // If the env updates we want to re-init the client, but that will happen due to the useEnvironment hook used by the useMemo
  const authLink = new ApolloLink((operation, forward) => {
    // this is an ugly hack here, but there is a first render race condition where the Apollo client is being used
    // prior to the useEffect above from firing.  So if the latestAuthState is null, fallback to the current authSate,
    // future renders will use the latestAuthState.
    const token =
      latestAuthState.current?.accessToken?.accessToken ||
      authState?.accessToken?.accessToken;
    const idToken =
      latestAuthState.current?.idToken?.idToken || authState?.idToken?.idToken;
    operation.setContext(({headers = {}}) => ({
      headers: {
        ...headers,
        "authorization": token ? `Bearer ${token}` : "",
        "X-Bridge-Id": idToken ? `Bearer ${idToken}` : ""
      }
    }));
    return forward(operation);
  });

  const client = useMemo<ApolloClient<NormalizedCacheObject>>(() => {
    const switchEndpoint = (endpoint: Endpoints) => {
      switch (endpoint) {
        case Endpoints.BACKEND: {
          return `${environment.backend.baseUri}/graphql`;
        }
        case Endpoints.APPSYNC: {
          return `${environment.appsync}`;
        }
        default: {
          return `${environment.backend.baseUri}/graphql`;
        }
      }
    };

    return new ApolloClient({
      link: from([
        removeTypenameFromVariables(),
        setCorrelationIdHeader,
        authLink,
        new HttpLink({
          uri: operation => {
            const endpoint = operation.getContext().endpoint;
            return switchEndpoint(endpoint);
          }
        })
      ]),
      cache: new InMemoryCache({
        typePolicies: {
          Oem: {
            keyFields: ["nameplateDescription", "abbreviation"]
          }
        }
      }),
      connectToDevTools
    });

    // Let's have authSate.isAuthenticated in here so that we will re-init when the auth state changes to true on initial load
    // Ideally we would only return a valid auth provider when the auth state is valid, but we can't do that here without some
    // ux work to show a loading spinner for this component.
    //
    // Let's also disable the exhaustive deps rule here because we don't want to re-init the client
    // when the authLink function is updated.
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [environment, authState?.isAuthenticated, connectToDevTools]);

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
