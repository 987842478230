import {TabBody} from "../../../tab-content";
import {HashTab} from "../../../hash-tabs";
import "./OperationalOverviewTab.scss";
import {useOperationalEntity} from "../../context";
import {OperationalDetailsColumn} from "./OperationalDetailsColumn";
import {useRecentlyViewedOperationalEntities} from "../../../hooks";
import {useEffect} from "react";
import {OperationalBoidsColumn} from "../../boids";
import {HistoryTabColumn} from "../../history";
import {useFlags} from "launchdarkly-react-client-sdk";
import {features} from "../../../features";

export const OperationalOverviewTab: HashTab = () => {
  const {
    entity: {id}
  } = useOperationalEntity();
  const {addBrowsedOperationalEntity} = useRecentlyViewedOperationalEntities();
  const flags = useFlags();

  useEffect(() => addBrowsedOperationalEntity(id), [id]);

  return (
    <TabBody name={"overview"}>
      <OperationalDetailsColumn />
      <OperationalBoidsColumn />
      {!!flags[features.dev.components.operationalHistory] && (
        <HistoryTabColumn id={id} />
      )}
    </TabBody>
  );
};
