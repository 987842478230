import {DeploymentType} from "./DeploymentType";
import {Runtime} from "@common-core/runtime-js/runtime";
import {Partition} from "@common-core/runtime-js/partition";

export interface Metadata {
  type: DeploymentType;
}

export function isDz(metadata?: Metadata): boolean {
  return (
    metadata?.type === DeploymentType.DZ ||
    metadata?.type === DeploymentType.DZ_INTEGRATION ||
    metadata?.type === DeploymentType.DZ_LEGACY
  );
}

export function isLive(metadata?: Metadata): boolean {
  return (
    metadata?.type === DeploymentType.LIVE ||
    metadata?.type === DeploymentType.LIVE_INTEGRATION ||
    metadata?.type === DeploymentType.LIVE_NEW ||
    metadata?.type === DeploymentType.LIVE_NEW_INTEGRATION
  );
}

export function isIntegration(metadata?: Metadata): boolean {
  return (
    metadata?.type === DeploymentType.LIVE_NEW_INTEGRATION ||
    metadata?.type === DeploymentType.LIVE_INTEGRATION ||
    metadata?.type === DeploymentType.DZ_INTEGRATION
  );
}

export function isLiveProd(runtime: Runtime<Metadata>): boolean {
  return (
    runtime.deployment.metadata?.type === DeploymentType.LIVE &&
    runtime.partition === Partition.PROD
  );
}
